<template>
    <div class="detail-box" v-if="config">
        <div class="title-box" :style="config.listCard ? 'background:var(--themeColor)' : ''">
            <i-header :title="config['title'] ? config['title'].template : ''"
                      :theme="config.listCard ? 'light' : ''" @back-event="toBack">
            </i-header>
        </div>

        <div id="scrollCnt" class="cnt-box" :style="$route.query.readOnly ? 'height:calc(100vh - 0.44rem)' : ''">
            <div class="card-box" v-if="config.listCard">
                <ListCard :show-btn="false" :template="config.listCard.template"
                          style="margin-bottom: 0.3rem" :info="$smartStorage.get('genericDetailInfo')"></ListCard>
            </div>
            <div class="form-box" v-if="template.length > 0">
                <i-form v-if="!$route.query.readOnly" ref="form" @submit="onSubmit" scroll-to-error>
                    <iFormItem v-for="(item, index) in template" :key="index" :form-data="formData"
                               :formTemplate="[template]" @callBack="fromCallback"
                               :model-val="formData[item.controlName]"
                               :form-item="item"></iFormItem>
                </i-form>
                <div v-else class="info-box">
                    <div class="info-box-item" v-for="(item, index) in template" :key="index">
                        <div class="info-item-title">{{ item.label }}</div>
                        <div class="info-item-cnt">{{ formData[item.controlName] || '暂无' }}</div>
                    </div>
                </div>
                <iFinderFrame v-for="(file, index) in fileList" :key="index" :uploaderSettings="file"></iFinderFrame>
                <!--讲者协议涵-->
                <div class="download-file" v-if="info.agreementURL && info.canEditMiceAgreement"
                     @click="downloadFile(info.agreementURL)">
                    点击下载《讲者协议函》
                </div>
            </div>
        </div>

        <div class="confirm-box" v-show="!$route.query.readOnly">
            <!--        <div class="confirm-box">-->
            <i-button round color="var(--themeColor)" block @click="btnClick" :loading="btnLoading">确定</i-button>
        </div>
    </div>
</template>

<script>
import ListCard from "./ListCard";
import iFormItem from "@/components/iFormItem/iFormItem";
import iFinderFrame from "@/components/iFinderFrame"
import generic from "../../utils/generic";
import downloadFile from "../../utils/downloadFile";

export default {
    name: "Detail",
    components: {
        ListCard,
        iFormItem,
        iFinderFrame
    },
    data() {
        return {
            info: this.$smartStorage.get('genericDetailInfo') || {},
            formData: {},
            template: [],
            fileList: [],
            checkList: [],
            btnLoading: false,
            config: null,

            cacheTemplate: [],   // 缓存form模版
        }
    },
    created() {
        console.log(this.$smartStorage.get('genericDetailInfo'))
        this.getConfig()
        // this.queryTemplate()
        // this.getFilesTemplate()
    },
    mounted() {
        window.downloadFileCallback = this.downloadFileCallback;
    },
    methods: {
        fromCallback(value, index, formItem) {
            if (value) {
                this.formData[formItem.controlName] = value[formItem.txtName] || value;
            } else {
                this.formData[formItem.controlName] = "";
            }
            // 执行表单配置功能
            if (formItem.needFilterTemplate) {
                this.formVisible(value, index, formItem)
            }
        },
        getConfig() {
            generic.getConfigs({
                miceId: this.$route.query.miceId,
                group: this.$route.query.type,
                router: this.$route.path
            },
                Object.assign(this.$smartStorage.get('miceInfo') || {}, { readOnly: this.$route.query.readOnly }))
                .then((config) => {
                    this.config = config
                    for (const configKey in config) {
                        if (config[configKey].callback) this[config[configKey].callback]()
                    }
                });
        },
        toBack() {
            this.$router.back()
        },
        async queryFormTemplate() {
            let userInfo = this.$smartStorage.get('userMsg') || {}
            userInfo.profileData = JSON.parse(userInfo.profileData || "{}")
            let context = {
                miceInfo: this.$smartStorage.get('miceInfo'),
                userInfo: userInfo,
                speakerInfo: this.info
            }
            let _config = generic.replaceEvalObject(this.config['formTemplate'],context)
            const res = await this.$service.GenericPost(_config);

            if (res && res.success && res.content) {
                this.cacheTemplate = this.jsonCopy(res.content.template)
                let arr = []
                res.content.template.map((element) => {
                    let tmp = generic.replaceEvalObject(element,
                        { Status: this.$smartStorage.get('miceInfo')?.Status || '', path: this.$route.path })
                    arr.push(tmp)
                    this.$set(this.formData, element.controlName, this.info?.extData[element.controlName] || this.info[element.controlName]);
                    return element;
                });
                this.checkList = res.content.checkList || []

                this.template = arr
            }
        },
        async queryFileTemplate() {
            let _config = generic.replaceEvalObject(this.config['fileTemplate'],
                { StatusTxt: this.$smartStorage.get("miceInfo")?.StatusTxt || '' })
            const res = await this.$service.GenericPost(_config);
            if (res.success && res.content) {
                res.content.uploadOptions.map((item) => {
                    item.onlyPreview = this.replaceVal(item.onlyPreview)
                    item.filterData = {
                        catalog: item.catalog,
                        relatedId: this.info.speakerId,
                        processId: this.$route.query.miceId,
                    };
                    return item
                });
                this.fileList = res.content.uploadOptions;
            }
        },
        replaceVal(evalStr) {
            if (typeof evalStr == 'boolean') return evalStr
            let context = this.$smartStorage.get('miceInfo') || {}
            let _this = this
            let val = false
            try {
                val = eval(evalStr)
            } catch (error) {
                console.log(`${evalStr}_error`, error)
            }
            return val
        },
        btnClick() {
            this.$refs.form.validate().then(() => {
                this.$refs.form.submit()
            }).catch(() => {
                this.$itoast('请完善必填信息')
            })
        },
        onSubmit(value) {
            let context = this.jsonCopy(value)
            context.standardFee = this.info.standardFee
            let checkExpr = this.checkList.filter((item) => {
                return eval(item.expr)
            })
            let sign = true
            if (checkExpr.length > 0) {
                checkExpr.map((val) => {
                    val.rules.map((v) => {
                        if (eval(v.expr)) {
                            this.$idialog
                                .alert({
                                    title: "提示",
                                    message: v.errorMsg,
                                })
                                .then(() => {
                                });
                            sign = false
                        }
                    })
                })
            }
            if (!sign) return false
            let _config = generic.replaceEvalObject(this.config['edit'], Object.assign(this.info, value))
            this.$service.GenericPost(_config).then((res) => {
                if (res && res.success) {
                    this.$itoast("修改成功！")
                    this.$router.back()
                }
            })
        },
        formVisible(value, index, formItem) {
            // 根据表单项的值控制其他表单项的显隐藏
            let context = Object.assign(this.$smartStorage.get('miceInfo'),{path: this.$route.path},this.formData)

            // console.log("====formitem的callback", formItem.controlName, value);
            let cacheTem = []
            this.cacheTemplate.map((item) => {
                // 匹配需要eval的字段
                let cacheItem = !item.needFilterTemplate ? generic.replaceEvalObject(item,context) : item
                if (item.visibleRuleExpr) {
                    // 命中规则才显示
                    try {
                        if(item.visibleRuleExpr.every((subItem) => eval(subItem))) {
                            cacheTem.push(cacheItem)
                        }
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    cacheTem.push(cacheItem)
                }
            })
            this.template = cacheTem
        },
        downloadFileCallback(res) {
            let _res = JSON.parse(res)
            let fileRoute = _res.data
            if(_res) {
                let userAgent = navigator.userAgent;
                let isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                let isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1;
                // ios
                if(isIOS) fileRoute = '文件app -> 浏览 -> 我的iphone -> "会引擎"文件夹下'
                if(isAndroid) fileRoute = '内部存储设备 -> Download -> "会引擎"文件夹下'
                this.$idialog
                    .alert({
                        title: "提示",
                        message: _res.msg + '，文件路径：' + fileRoute
                    })
                    .then(() => {
                    });
            }
        },
        downloadFile(url) {
            if (window.flutter_inappwebview) {
                let params = {
                    postData: {
                        "url": url,
                        "fielName": "讲者协议函",
                    },
                    // callback: "downloadFileCallback"
                    callback: ""
                }
                window.flutter_inappwebview.callHandler(
                    "SmFileManager.downloadFile", JSON.stringify(params)
                );
            } else {
                window.open(url)
            }
            // downloadFile.doExecute(url,"讲者协议函")
        }
    }
}
</script>

<style lang="less" scoped>
.detail-box {
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &-header {
        height: 1.04rem;
        box-sizing: border-box;
        background-color: #ffffff !important;
    }

    .cnt-box {
        flex: 1;
        overflow-y: auto;
        //height: calc(100vh - 1.93rem);

        .card-box {
            width: 100%;
            background: var(--themeColor);
            height: 0.7rem;
            margin-bottom: 0.8rem;

            .item-box {
                background: #FFFFFF;
                box-shadow: 0px 0px 0.17rem 0px rgba(0, 0, 0, 0.08);
                border-radius: 0.04rem;
                border: unset;
                padding: 0.13rem 0.16rem;
                width: calc(100vw - 0.2rem);
                box-sizing: border-box;
                margin: 0 auto 0;

                /deep/ .pos-right {
                    top: 0.13rem !important;
                    right: 0.16rem !important;
                }
            }
        }

        .form-box {
            //margin-top: 0.3rem;
            padding: 0 0.18rem 0.3rem 0.18rem;
            box-sizing: border-box;
            .info-box {
                border-bottom: 1px solid #F5F5F5;
                &-item {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0.1rem;
                    align-items: center;
                    .info-item-title {
                        font-size: 0.15rem;
                        font-weight: 500;
                        color: #13161B;
                    }
                    .info-item-cnt {
                        max-width: 65%;
                        font-size: 0.15rem;
                        font-weight: 500;
                        color: #13161B;
                    }
                }
            }
            .download-file {
                padding: 0.15rem 0;
                box-sizing: border-box;
                color: blue;
                text-decoration: underline;
            }
        }
    }

    .confirm-box {
        //position: fixed;
        //bottom: 0;
        width: 100vw;
        height: 0.7rem;
        background: #FFFFFF;
        box-shadow: 0px -0.16rem 0.16rem 0px #FFFFFF;
        border-radius: 0.33rem 0.33rem 0px 0px;
        padding: 0.05rem 0.1rem 0 0.1rem;
        box-sizing: border-box;
        /deep/ .van-button__text {
            font-size: 0.16rem;
        }
    }
}
</style>
